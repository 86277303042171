<template>
    <KCourse
      loader-class="MBcont"
      course-id="8"
      chapter-id=83
      chapterPayment="/Membership/Beginner2/MBCourseBeginnerChapterPayment.vue"
      title="Будуарная кукла"
      :items="items"
    >
        <div class="MBtextcont">
            <h3>1. Подготовка</h3>
        </div>
                
        <VideoView video-id="a3b1e3f1d37d41c78094cca9252b6348"/>

      <div class="MBtextcont">
        <p class="nomargin"><b>Что нужно сделать:</b></p>
        <ol>
          <li>Собрать на окончательный крепкий узел руки (ноги не надо)</li>
          <li>Подготовить опилки</li>
        </ol>

        <h3>2. Выкраиваем тело</h3>
      </div>

      <VideoView video-id="2e33b102463c464099b53323510c8e71"/>

      <div class="MBtextcont">
        <h3>3. Соединяем руку</h3>

        <p>
          <router-link to="/mycourses/beginner2/19">Подробнее обо всех ручных швах тут</router-link>
        </p>
      </div>

      <VideoView video-id="c226d61c6c3b4437bd61fb8d6fa7408e"/>

      <div class="MBtextcont">
        <h3>4. Соединяем ногу</h3>
      </div>

      <VideoView video-id="b6d4a1f03e78401791221e496a4a1587"/>

      <div class="MBtextcont">
        <h3>5. Пришиваем ноги</h3>
      </div>

      <VideoView video-id="862e1828040a4f34b8e86c5bfad0793d"/>

      <div class="MBtextcont">
        <h3>6. Декорируем тело</h3>
      </div>

      <VideoView video-id="b7360818375f4948a0b0ea149c414a89"/>


      <div class="MBtextcont">
        <h3 class="nomargin">Итого, что нужно сделать:</h3>
        <ol>
          <li><b>Всё собрать и задекорировать</b></li>
          <li><b>Подготовить материалы</b> к следующему занятию:</li>
          <ol type="a">
            <li>Глянцевый лак для глаз и губ, можно сатиновый (по желанию)</li>
            <li>Ресницы (желательно коричневые, если ваша девочка блондинка или в пастельных тонах)</li>
            <li>Клей "Секунда"</li>
            <li>Жидкость для снятия лака</li>
            <li>Тонкая кисть</li>
          </ol>
        </ol>

        <h3>Время выполнения: 5 часов</h3>
      </div>
    </KCourse>
</template>

<script>
import VideoView from "../../Widgets/VideoView";
import KCourse from "../../Widgets/KCourse";
import chapters from './data.js';
import KImage from "../../Widgets/KImage";

export default {
  name: "MBCourseHead",
  components: {
    VideoView,
    KCourse,
    KImage
  },
  data() {
    return {
      items: chapters
    }
  },
  methods: {
    showImage(src, customClass) {
      this.$swal.fire({
        customClass: customClass,
        background: '#ffffff',
        html: '<img width="100%" src="' + src + '"/>',
        showCloseButton: true,
        showConfirmButton: false,
        // closeButtonHtml: "",
      })
    },
  }
}
</script>

<style>

</style>